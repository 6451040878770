import {Card, Image} from "@nextui-org/react";
import {useState, useEffect} from "react";
import Swipe from "react-easy-swipe";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import NextImage from "next/image";
import sharedService from "@/services/shared-service";
import SharedServiceInstance from "@/services/shared-service";
import {IconButton} from "@material-tailwind/react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import Link from "next/link";
import LoadingCommon from "@/components/shared/loadingCommon";

/**
 * TopSliderCarousel component for nextJS and Tailwind.
 * Using external library react-easy-swipe for swipe gestures on mobile devices (optional)
 *
 * @param images - Array of images with src and alt attributes
 * @returns React component
 */
export default function TopSliderCarousel({images}) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    // Preload all images
    useEffect(() => {
        const preloadImages = async () => {
            const imagePromises = images.map((image) => {
                return new Promise((resolve) => {
                    const img = new window.Image();
                    img.src = SharedServiceInstance.getOptimizeImage(image.photo, "top-slider");
                    img.onload = () => resolve(img.src);
                    img.onerror = () => resolve(null);
                });
            });
            await Promise.all(imagePromises);
            setIsLoading(false);
        };

        preloadImages();
    }, [images]);

    const handleNextSlide = () => {
        let newSlide = currentSlide === images.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(newSlide);
    };

    const handlePrevSlide = () => {
        let newSlide = currentSlide === 0 ? images.length - 1 : currentSlide - 1;
        setCurrentSlide(newSlide);
    };

    const reversed = [...images].reverse();

    return (
        <div className="group relative mt-3 rounded-[5px]">
            <div className="hidden group-hover:block z-50">
                <IconButton
                    variant="text"
                    color="white"
                    size="sm"
                    onClick={handlePrevSlide}
                    className="!absolute top-1/2 !left-0 -translate-y-2/4 text-2xl lg:text-5xl bg-gray-600/60 z-20 animate-in fade-in duration-200"
                >
                    <FaAngleLeft className="fill-white text-2xl" />
                </IconButton>

                <IconButton
                    variant="text"
                    color="white"
                    size="sm"
                    onClick={handleNextSlide}
                    className="!absolute top-1/2 !right-0 -translate-y-2/4 text-2xl lg:text-5xl bg-gray-600/60 z-20 animate-in fade-in duration-200"
                >
                    <FaAngleRight className="fill-white text-2xl" />
                </IconButton>
            </div>
            <div className="w-full flex overflow-hidden relative m-auto rounded-t-[5px]">
                <Swipe
                    onSwipeLeft={handleNextSlide}
                    onSwipeRight={handlePrevSlide}
                    className="z-10 w-full h-full max-h-[152px]"
                >
                    {isLoading ? (
                        <LoadingCommon itemName={"dữ liệu"} />
                    ) : (
                        <div className="flex w-full h-full">
                            {reversed.map((image, index) => (
                                <Card
                                    href={image.link}
                                    as={Link}
                                    key={index}
                                    radius="none"
                                    className={`w-full h-full col-span-12 sm:col-span-7 rounded-t-[5px] flex-shrink-0 ${
                                        index === currentSlide ? "block" : "hidden"
                                    }`}
                                >
                                    <NextImage
                                        fill
                                        priority={index === 0}
                                        quality={100}
                                        loading="eager"
                                        alt={`banner-${index}`}
                                        className="z-0 rounded-t-[5px] !w-full !relative object-contain md:!h-[unset]"
                                        src={`${SharedServiceInstance.getOptimizeImage(image.photo, 'top-slider')}`}
                                    />
                                </Card>
                            ))}
                        </div>
                    )}
                </Swipe>
            </div>

            <div className="sm:p-2 z-20 w-full bg-white h-4 rounded-b-[5px]">
                <div className="flex flex-grow gap-0.5 sm:gap-2 items-center justify-center h-full">
                    {images.map((_, index) => {
                        return (
                            <div
                                className={
                                    index === currentSlide
                                        ? "h-2 w-4 bg-primary rounded-full cursor-pointer animate-shake duration-300"
                                        : "h-2 w-2 bg-gray-600 rounded-full cursor-pointer animate-shake duration-300"
                                }
                                key={index}
                                onClick={() => {
                                    setCurrentSlide(index);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}